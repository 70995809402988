import axios from 'axios'
import Const from '../constants'
import axiosApiInstance from '../interceptors'
import authHeader from './auth-header'
import router from '@/router'
import store from "@/store";

class AuthService {
  login(email: string, password: string) {
    return axiosApiInstance
      .post(Const.api_url + 'auth/login', {
        email,
        password
      })
      .then((response: any) => {
        if (response.data.access_token) {
          localStorage.setItem('token', JSON.stringify(response.data.access_token))
          localStorage.setItem('user', JSON.stringify(response.data))
          localStorage.setItem('mode_purchases', '1')
        }
        return response.data
      })
  }

  logout() {
    return axiosApiInstance.post(Const.api_url + 'auth/logout')
      .then((response: any) => {
        if (response.status) {
          localStorage.clear()
          router.push({ name: 'login' })
        }
      })
  }

  async refreshAccessToken() {
    return axios
      .get(Const.api_url + 'auth/refresh', { headers: authHeader() })
      .then(
        (response: any) => {
          let data = response.data.items
          if (data.new_token) {
            localStorage.setItem('token', JSON.stringify(data.new_token))
          }
          return data.new_token
        },
        (error: any) => {
          if (error) {
              store.dispatch('Auth/clearLoginUser')
              localStorage.removeItem('token')
              localStorage.removeItem('user')
              router.push({ name: 'store-first' });
          }
        })
  }
}

export default new AuthService()
