import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/store-first',
    name: 'store-first',
    component: () => import('../views/StoreFirst.vue'),
    meta: {
      title: '明治屋ネットストアー | トップページ'
    }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '明治屋ネットストアー | 会員様用 トップページ'
    }
  },
  {
    path: '/todo',
    name: 'todo',
    component: () => import('../views/Todo.vue'),
    meta: {
      title: 'Todo'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '明治屋ネットストアー | ログイン'
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/ForgotPassword.vue'),
    meta: {
      title: '明治屋ネットストアー | パスワード忘れ'
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/ResetPassword.vue'),
    meta: {
      title: 'パスワード再設定'
    }
  },
  {
    path: '/reset-password-success',
    name: 'reset-password-success',
    component: () => import('../views/ResetPasswordSuccess.vue'),
    meta: {
      title: '明治屋ネットストアー | パスワード再設定完了'
    }
  },
  {
    path: '/information-commercial-transactions',
    name: 'information-commercial-transactions',
    component: () => import('../views/InformationCommercialTransactions.vue'),
    meta: {
      title: '明治屋ネットストアー | 特定商取引に基づく表記'
    }
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('../views/AboutUs.vue'),
    meta: {
      title: '明治屋ネットストアー | 会社情報'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: {
      title: '明治屋ネットストアー | プライバシーポリシー'
    }
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import('../views/TermsOfService.vue'),
    meta: {
      title: '明治屋ネットストアー | 利用規約'
    }
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('../views/ContactUs.vue'),
    meta: {
      title: '明治屋ネットストアー | お問い合わせ'
    }
  },
  {
    path: '/membership-information',
    name: 'membership-information',
    component: () => import('../views/MembershipInformation.vue'),
    meta: {
      title: '会員情報'
    }
  },
  {
    path: '/confirm-membership-information',
    name: 'confirm-membership-information',
    component: () => import('../views/ConfirmMembershipInformation.vue'),
    meta: {
      title: '明治屋ネットストアー | 会員情報変更確認'
    }
  },
  {
    path: '/member-select-store',
    name: 'member-select-store',
    component: () => import('../views/MemberSelectStore.vue'),
    meta: {
      title: '明治屋ネットストアー | 店舗選択'
    }
  },
  {
    path: '/member-no-select-store',
    name: 'member-no-select-store',
    component: () => import('../views/MemberNoSelectStore.vue'),
    meta: {
      title: '明治屋ネットストアー | 店舗選択 該当店舗なし'
    }
  },
  {
    path: '/select-store',
    name: 'select-store',
    component: () => import('../views/SelectStore.vue'),
    meta: {
      title: '明治屋ネットストアー | 郵便番号設定'
    }
  },
  {
    path: '/category',
    name: 'category',
    component: () => import('../views/CategoryList.vue'),
    meta: {
      title: 'Category List'
    }
  },
  {
    path: '/category/:category_id/product',
    name: 'category-product',
    component: () => import('../views/ProductList.vue'),
    meta: {
      title: '明治屋ネットストアー | カテゴリ一覧'
    }
  },
  {
    path: '/category/:category_id/product',
    name: 'category-product-mid',
    component: () => import('../views/ProductList.vue'),
    meta: {
      title: '明治屋ネットストアー | カテゴリ一覧'
    }
  },
  {
    path: '/category/:category_id/product',
    name: 'category-product-small',
    component: () => import('../views/ProductList.vue'),
    meta: {
      title: '明治屋ネットストアー | カテゴリ一覧'
    }
  },
  {
    path: '/product',
    name: 'product-search',
    component: () => import('../views/ProductListSearchResult.vue'),
    meta: {
      title: '明治屋ネットストアー | バーコード読み込み結果'
    }
  },
  {
    path: '/product-favourite',
    name: 'product-favourite',
    component: () => import('../views/ProductFavourite.vue'),
    meta: {
      title: '明治屋ネットストアー | お気に入り一覧'
    }
  },
  {
    path: '/select-store-success',
    name: 'select-store-success',
    component: () => import('../views/SelectStoreSuccess.vue'),
    meta: {
      title: '明治屋ネットストアー | 店舗選択'
    }
  },
  {
    path: '/select-store-error',
    name: 'select-store-error',
    component: () => import('../views/SelectStoreError.vue'),
    meta: {
      title: 'Select Store Error'
    }
  },
  {
    path: '/member-register',
    name: 'member-register',
    component: () => import('../views/MemberRegister.vue'),
    meta: {
      title: '明治屋ネットストアー | 会員登録'
    }
  },
  {
    path: '/member-register-status',
    name: 'member-register-status',
    component: () => import('../views/MemberRegisterStatus.vue'),
    meta: {
      title: '明治屋ネットストアー | 会員登録完了'
    }
  },
  {
    path: '/member/active/:id',
    name: 'member-register-success',
    component: () => import('../views/MemberRegisterSuccess.vue'),
    meta: {
      title: '会員登録完了'
    }
  },
  {
    path: '/select-time-zone-delivery',
    name: 'select-time-zone-delivery',
    component: () => import('../views/SelectTimeZoneDelivery.vue'),
    meta: {
      title: '明治屋ネットストアー | 配達便設定'
    }
  },
  {
    path: '/select-time-zone-delivery-edit/:order_id',
    name: 'select-time-zone-delivery-edit',
    component: () => import('../views/SelectTimeZoneDeliveryEdit.vue'),
    meta: {
      title: '明治屋ネットストアー | 配達便設定'
    }
  },
  {
    path: '/select-time-zone-delivery-merge/:order_id',
    name: 'select-time-zone-delivery-merge',
    component: () => import('../views/SelectTimeZoneDeliveryMerge.vue'),
    meta: {
      title: '明治屋ネットストアー | 配達便設定'
    }
  },
  {
    path: '/inquiry-result',
    name: 'inquiry-result',
    component: () => import('../views/InquiryResult.vue'),
    meta: {
      title: '明治屋ネットストアー | お問い合わせ失敗'
    }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('../views/ChangePassword.vue'),
    meta: {
      title: 'パスワードの変更'
    }
  },
  {
    path: '/change-password-method',
    name: 'change-password-method',
    component: () => import('../views/ChangePasswordMethod.vue'),
    meta: {
      title: 'Change Password Method'
    }
  },
  {
    path: '/change-password-result',
    name: 'change-password-result',
    component: () => import('../views/ChangePasswordResult.vue'),
    meta: {
      title: 'パスワードの変更'
    }
  },
  {
    path: '/order-completed',
    name: 'order-completed',
    component: () => import('../views/OrderCompleted.vue'),
    meta: {
      title: '明治屋ネットストアー | 注文完了'
    }
  },
  {
    path: '/purchase-history',
    name: 'purchase-history',
    component: () => import('../views/PurchaseHistory.vue'),
    meta: {
      title: '明治屋ネットストアー | 購入履歴'
    }
  },
  {
    path: '/credit-card-verification',
    name: 'credit-card-verification',
    component: () => import('../views/CreditCardVerification.vue'),
    meta: {
      title: '明治屋ネットストアー | 登録済み クレジットカード情報'
    }
  },
  {
    path: '/change-credit-card',
    name: 'change-credit-card',
    component: () => import('../views/ChangeCreditCard.vue'),
    meta: {
      title: '明治屋ネットストアー | クレジットカードの変更'
    }
  },
  {
    path: '/change-credit-card-confirmation-code',
    name: 'change-credit-card-confirmation-code',
    component: () => import('../views/ChangeCreditCardConfirmationCode.vue'),
    meta: {
      title: 'Change CreditCard Confirmation Code'
    }
  },
  {
    path: '/change-credit-card-confirmation-code-status',
    name: 'change-credit-card-confirmation-code-status',
    component: () => import('../views/ChangeCreditCardConfirmationCodeStatus.vue'),
    meta: {
      title: 'Change CreditCard Confirmation Code Status'
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/CartContent.vue'),
    meta: {
      title: '明治屋ネットストアー | 買い物カゴ'
    }
  },
  // {
  //   path: '/flyer',
  //   name: 'flyer',
  //   component: () => import('../views/Flyer.vue'),
  //   meta: {
  //     title: 'チラシから購入 | 明治屋ネットストアー'
  //   }
  // },
  {
    path: '/delete-credit-card-status',
    name: 'delete-credit-card-status',
    component: () => import('../views/DeleteCreditCardStatus.vue'),
    meta: {
      title: 'Delete CreditCard Status'
    }
  },
  {
    path: '/product-detail-preview',
    name: 'product-detail-preview',
    component: () => import('../views/ProductDetailPreview.vue'),
    meta: {
      title: '****  | 明治屋ネットストアー'
    }
  },
  {
    path: '/withdraw-procedure',
    name: 'withdraw-procedure',
    component: () => import(/* webpackChunkName: "withdraw-procedure" */ '../views/WithdrawProcedure.vue'),
    meta: {
      title: '明治屋ネットストアー | 退会手続き'
    }
  },
  {
    path: '/suggestion',
    name: 'suggestion',
    component: () => import('../views/Suggestion.vue'),
    meta: {
      title: '明治屋ネットストアー | 当店の人気商品'
    }
  },
  {
    path: '/time-delivery-and-deadline',
    name: 'time-delivery-and-deadline',
    component: () => import('../views/TimeDeliveryAndDeadline.vue'),
    meta: {
      title: '明治屋ネットストアー | 配達便案内'
    }
  },
  {
    path: '/question-and-answer',
    name: 'question-and-answer',
    component: () => import('../views/QAndA.vue'),
    meta: {
      title: '明治屋ネットストアー | よくあるご質問'
    }
  },
  {
    path: '/barcode-scan',
    name: 'barcode-scan',
    component: () => import('../views/BarCodeScan.vue'),
    meta: {
      title: '明治屋ネットストアー | バーコード読み込み'
    }
  },
  {
    path: '/article/:url',
    name: 'article',
    component: () => import('../views/LandingPage.vue'),
    meta: {
      title: '明治屋ネットストアー | 記事'
    }
  },
  // {
  //   path: '/subscription-setting',
  //   name: 'subscription-setting',
  //   component: () => import('../views/SubscriptionSetting.vue'),
  //   meta: {
  //     title: '明治屋ネットストアー | 定期購入設定一覧'
  //   }
  // },
  // {
  //   path: '/subscription-setting-detail/:order_id',
  //   name: 'subscription-setting-detail',
  //   component: () => import('../views/SubscriptionSettingDetail.vue'),
  //   meta: {
  //     title: '明治屋ネットストアー | 定期購入設定詳細'
  //   }
  // },
  // {
  //   path: '/subcription-setting-change-next-delivery-date',
  //   name: 'subcription-setting-change-next-delivery-date',
  //   component: () => import('../views/SubscriptionSettingChangeNextDeliveryDate.vue'),
  //   meta: {
  //     title: '明治屋ネットストアー | 定期購入次回お届け日変更'
  //   }
  // },
  // {
  //   path: '/subcription-setting-change-next-delivery-date-edit',
  //   name: 'subcription-setting-change-next-delivery-date-edit',
  //   component: () => import('../views/SubscriptionSettingChangeNextDeliveryDateEdit.vue'),
  //   meta: {
  //     title: '明治屋ネットストアー | 定期購入次回お届け日変更'
  //   }
  // },
  // {
  //   path: '/subcription-setting-change-subscription-cycle',
  //   name: 'subcription-setting-change-subscription-cycle',
  //   component: () => import('../views/SubscriptionSettingChangeSubscriptionCycle.vue'),
  //   meta: {
  //     title: '明治屋ネットストアー | 定期購入定期配達サイクル変更'
  //   }
  // },
  // {
  //   path: '/subcription-setting-change-subscription-cycle-edit',
  //   name: 'subcription-setting-change-subscription-cycle-edit',
  //   component: () => import('../views/SubscriptionSettingChangeSubscriptionCycleEdit.vue'),
  //   meta: {
  //     title: '明治屋ネットストアー | 定期購入定期配達サイクル変更'
  //   }
  // },
  {
    path: '/order-merge-select',
    name: 'order-merge-select',
    component: () => import('../views/OrderMergeSelect.vue'),
    meta: {
      title: '注文ページ'
    }
  },
  {
    path: '/order-merge/:order_id',
    name: 'order-merge',
    component: () => import('../views/OrderMerge.vue'),
    meta: {
      title: '明治屋ネットストアー | 注文内容変更'
    }
  },
  {
    path: '/order-edit/:order_id',
    name: 'order-edit',
    component: () => import('../views/OrderEdit.vue'),
    meta: {
      title: '明治屋ネットストアー | 注文内容変更'
    }
  },
  {
    path: '/grand-open',
    name: 'grand-open',
    component: () => import('../views/GrandOpen.vue'),
    meta: {
      title: '明治屋ネットストアー プレオープン'
    }
  },
  // {
  //   path: '/page-not-found',
  //   name: 'page-not-found',
  //   component: () => import('../views/PageNotFound.vue'),
  //   meta: {
  //     title: '404 Page Not Found'
  //   }
  // },
  {
    path: '*',
    redirect: '/page-not-found'
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  const publicPageWhenSelectedStore =
    [
      'home', 'product-detail-preview', 'product-search',
      'flyer', 'subscription-setting', 'category-product', 'category-product-mid', 'category-product-small',
      'contact-us'
    ];
  const publicFull = [
    'login', 'store-first', 'select-store', 'forgot-password', 'reset-password', 'reset-password-success',
    'privacy-policy', 'select-store-success', 'select-store-error',
    'information-commercial-transactions', 'about-us', 'question-and-answer',
    'terms-of-service', 'member-register', 'member-register-status', 'barcode-scan', 'member-register-success', 'grand-open',
    'article'
  ]
  const name = to && to.name ? to.name : ''
  const authRequired = publicPageWhenSelectedStore.includes(name);

  const loggedIn = localStorage.getItem('user');
  const selectedStore = localStorage.getItem('store_uuid') || null;

  if (publicFull.includes(name)) {
    next()
  } else if (!selectedStore) {
    next({ name: 'store-first' })
  } else if (!loggedIn && !authRequired) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
